import React from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './text-box-grid.module.scss';
import useLanguage from '../_hooks/useLanguage';

const TextBoxGrid = () => {
	const { ref, inView } = useInView({
		threshold: 0,
		triggerOnce: true
	});

	const { t } = useLanguage();

	return (
		<div className={styles.default} ref={ref}>
			{inView && (
				<div className="container">
					<div className="row">
						<article className="col-md-4">
							<i className="icon telescope" />
							<p><strong>{t('exposedHiwTitle1')}</strong></p>
							<p>{t('exposedHiwText1')}</p>
						</article>
						<article className="col-md-4">
							<i className="icon angel" />
							<p><strong>{t('exposedHiwTitle2')}</strong></p>
							<p>{t('exposedHiwText2')}</p>
						</article>
						<article className="col-md-4">
							<i className="icon lamp-desk" />
							<p><strong>{t('exposedHiwTitle3')}</strong></p>
							<p>{t('exposedHiwText3')}</p>
						</article>
					</div>
				</div>
			)}
		</div>
	);
};

export default TextBoxGrid;
