import React, { useState } from 'react';

import styles from './index.module.scss';
import Layout from '../components/layout/layout';
import Hero from '../components/hero/hero';
import TextBoxGrid from '../components/text-box-grid/text-box-grid';
import ItemList from '../components/item-list/item-list';
import TextBoxCta from '../components/text-box-cta/text-box-cta';
import LoaderSpinner from '../components/loader/loader-spinner';
import useLanguage from '../components/_hooks/useLanguage';
import useUser from '../components/_hooks/useUser';
import useConfig from '../components/_hooks/useConfig';

const Home = () => {
	const { t } = useLanguage();
	const { isMember } = useUser();
	const { clientConfig } = useConfig();

	const [listLoadedCnt, setListLoadedCnt] = useState(0);

	return (
		<Layout variation="hero" bodyClass={styles.default}>
			<Hero />
			{clientConfig('FEATURE_GRID') && <TextBoxGrid />}
			<div className={styles.itemLists}>
				{listLoadedCnt < 2 && <LoaderSpinner>{t('labelLoadingAuctions')}</LoaderSpinner>}
				{clientConfig('LANDING_LIST_LIVE_INPROGRESS') && <ItemList name="auctions-live-inprogress" title={t('itemListTitle5')} loader={false} onQueryEnd={() => setListLoadedCnt(listLoadedCnt + 1)} filters={{ auction_status_id: 50 }} perPage={20} carousel />}
				{clientConfig('LANDING_LIST_NEW') && <ItemList name="auctions-new" title={t('itemListTitle3')} loader={false} onQueryEnd={() => setListLoadedCnt(listLoadedCnt + 1)} noResult={listLoadedCnt >= 2 ? { title: t('labelNoResulteAuctions'), text: '' } : null} orderBy="modified_dtm DESC" perPage={20} link={clientConfig('LANDING_LIST_NEW_VIEW_ALL') ? '/drazbe' : null} carousel />}
				{clientConfig('LANDING_LIST_BEFORE_START') && <ItemList name="auctions-nearest" title={t('itemListTitle1')} loader={false} onQueryEnd={() => setListLoadedCnt(listLoadedCnt + 1)} filters={{ before_security_deposit: true }} orderBy="security_deposit_due_dt DESC" perPage={20} carousel />}
				{clientConfig('LANDING_LIST_AFTER_START') && <ItemList name="auctions-quarantine" title={t('itemListTitle4')} loader={false} onQueryEnd={() => setListLoadedCnt(listLoadedCnt + 1)} filters={{ after_security_deposit_before_event: true }} perPage={20} carousel />}
				{clientConfig('LANDING_LIST_ARCHIVED') && <ItemList name="nearest-expired" title={t('itemListTitle2')} loader={false} onQueryEnd={() => setListLoadedCnt(listLoadedCnt + 1)} filters={{ auction_status_id: 40 }} orderBy="auction_start_dtm DESC" perPage={20} carousel />}
			</div>
			{clientConfig('PRO_CTA') && !isMember && <TextBoxCta />}
		</Layout>
	);
};

export default Home;
